@font-face {
    font-family: "Roboto Condensed Light";
    src: url("./assets/Roboto_Condensed/RobotoCondensed-Light.ttf");
}

@font-face {
    font-family: "Roboto Condensed Regular";
    src: url("./assets/Roboto_Condensed/RobotoCondensed-Regular.ttf");
}

@font-face {
    font-family: "Roboto Condensed Bold";
    src: url("./assets/Roboto_Condensed/RobotoCondensed-Bold.ttf");
}

/*@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,700;1,300&display=swap');*/
:root{
    --p:0,72,130;
    --s:239,110,22;

    --primary:rgb(var(--p));
    --gb-primary-icon:rgba(var(--p), 0.20);
    --secondary:rgb(var(--s));
    --bg-secondary-icon:rgba(var(--s), 0.20);
    --text:#5F6566;
    --input:#1D1D1B;
}

html, body {
    height: 100%;
    margin: 0;
}
.rs-panel-heading,
.rs-dropdown-item-content,
button{
    font-family: "Roboto Condensed Regular";
}

.s-font-regular{
    font-family: "Roboto Condensed Regular" !important;
}
.s-primary{
    color:var(--primary) !important;
}

.s-background-primary > a:hover{
    background: var(--gb-primary-icon) !important;
}

.s-secondary{
    color: var(--secondary);
}

.s-primary-btn{
    background: var(--secondary);
    color:#fff;
}

.s-primary-btn:hover{
    background: var(--bg-secondary-icon);
    color: var(--secondary);
}

.s-background-secondary > a:hover{
    background: var(--bg-secondary-icon) !important;
}

.s-background-sidebar{
    background:#f7f8fb;
    height:100vh;
}

.s-image-home{
    text-align: center;
    margin: 0 auto;
    margin-top: 10%;
}

.s-username > a{
    font-family: "Roboto Condensed Regular";
    color: #838F93 !important;
}

.swal2-title,
.s-title-page{
    font-family: 'Roboto Condensed Bold';
    font-size: 34px;
    color:var(--primary);
    margin-left: 1.5%;
}


.s-table-titles{
    font-family: 'Roboto Condensed Bold';
    color: #1D71B8;
    font-size: 18px;
}

.swal2-content,
.s-table-content{
    font-family: 'Roboto Condensed Regular';
    color:var(--text);
    font-size: 16px;
}

.rs-control-label,
.rs-modal-title,
.s-content .rs-control-label,
.s-content span,
.s-content th{
    font-family: 'Roboto Condensed Regular';
    color:var(--text);
}

.s-content .form-control,
.s-content .rs-picker-toggle-value,
.form-control,
.s-content td{
    font-family: 'Roboto Condensed Light';
    color:var(--input);
}

.s-btn-primary{
    font-family: 'Roboto Condensed Bold';
    background: #1d71B8;
    color:#fff;
}

.s-rs-divider{
    background:#000;
    margin-bottom: 1px;
}

/*sobre escribe*/
.dataTables_filter > label{
    font-style: 'Roboto Condensed Regular';
    color:var(--text);
    font-size: 26px;
}

.rs-panel-heading{
    color:var(--primary);
}

.s-nav-item-content{
    padding: 15px 0 20px 20px !important;
}

.s-time-zone{
    opacity: 1;
}

.s-time-zone > a > span{
    color:var(--input) !important;
    
}
.s-w-map{
    width:99% !important;
}

.s-sign-out > .rs-icon{
    padding:1px 0 !important;
    font-size: 22px !important;
}

.s-input-suscription{
    box-shadow: rgb(50 50 93 / 15%) 0px 1px 3px, rgb(0 0 0 / 2%) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}
[class*="s-img-fluid"]{
    width: 5%;
}

[class*="s-menu-title"]{
    width: '80%';
    display: contents;
}

.panel-general.rs-panel-shaded {
    -webkit-box-shadow: 0 4px 4px rgb(29 113 184 / 60%), 0 0 10px rgb(29 113 184 / 30%);
    box-shadow: 0 4px 4px rgb(29 113 184 / 60%), 0 0 10px rgb(29 113 184 / 30%);
}

.panel-report.rs-panel-shaded{
    -webkit-box-shadow: 0 4px 4px rgb(0 107 181 / 60%), 0 0 10px rgb(0 107 181 / 30%);
    box-shadow: 0 4px 4px rgb(0 107 181 / 60%), 0 0 10px rgb(0 107 181 / 30%);
}
.panel-report.rs-panel-bordered{
    border: 1px solid #006bb5;
}
.image-panel-report{
    margin-top: 10px;
    padding: 6px;
    border: 3px solid #006bb5;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
}
.name-panel-report{
    margin-bottom: 10px;
    padding: 16px;
    border: 3px solid #006bb5;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Roboto Condensed Regular';
    color: #ff6700;
}

.icon-save-tax{
    margin-top: 2.5%;
}

.s-nav-item-content img{
    width: 65%;
}
.s-submenu-image,
.s-submenu-icon{
    display: none !important;
}

.login-form{
    margin-top:15%;
    text-align:center;
    width:97%;
    position:absolute;
    top:36%;
}

.unsubscribe, .delete-account{
    height: 265px;
}
.delete-account button{
    margin-top: 23px;
}

.title-plan{
    position: absolute; 
    top: 5%;
    width: 85%;
}
.title-plan span{
    font-family: "Roboto Condensed Regular";
    font-weight: bold;
    font-size: 43px;
    letter-spacing: 2.58px;
    color:#FFF;
}

.footer-plan{
    position:absolute;
    bottom:10px;
    height:20px;
    width:85%;
    border-radius:5px;
    
}

.fixed_table_scroll{
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.fixed_table_scroll tbody{
  display:block;
  width: 100%;
  overflow: auto;
  height: 200px;
}

.fixed_table_scroll thead tr {
   display: block;
}


@media only screen and (min-width:1024px) and (max-width: 1365px) {
    .title-plan{
        top: 6%;
        width: 90%;
    }
    .footer-plan{
        width: 90%;
    }
}


@media only screen and (min-width:768px) and (max-width: 1023px) {
    .unsubscribe, .delete-account{
        height: 265px;
    }

    .delete-account button{
        margin-top: 19px;
    }

    /* For desktop: */
    .s-img-fluid {
        width: 60%;
    }

    .s-menu-title{
        display: none;
    }

    .s-logo-img{
        margin: 4%;
        width: 80%;
    }

    .s-nav-item-content img{
        width: 10%;
        display: inline-block;
        margin-right: 10px;
    }

    .icon-save-tax{
        margin-top: 4%;
    }

    .s-nav-item-content .rs-icon{
        font-size:25px !important;
        top: -5px !important;
        left: 26px !important;
    }

    .s-nav-item-content .s-submenu-image{
        display: contents !important;
    }

    .s-nav-item-content .s-submenu-icon{
        display: inline-block !important;
        left:67px !important;
    }

    .login-form{
        top:35%;
    }
}

@media only screen and (min-width:414px) and (max-width:768px){
    
    .s-nav-item-content img{
        display: inline-block;
        margin-right: 10px;
        width: 12%;
    }

    .s-nav-item-content .s-submenu-image{
        display: contents !important;
    }

    .s-nav-item-content .rs-icon{
        font-size:25px !important;
        top: -5px !important;
        left: 26px !important;
    }

    .s-nav-item-content .s-submenu-icon{
        display: inline-block !important;
        left:67px !important;
    }

    .s-logo-img{
        margin:5%;
        width: 80%;
    }

    .icon-save-tax{
        margin-top: 5.5%;
    }

    .login-form{
        top:30%;
    }
}

@media only screen and (max-width:414px) {
    
    .unsubscribe, .delete-account{
        height: 230px;
    }
    .s-drawer{
        width: 85% !important;
    }

    .s-nav-item-content img{
        display: inline-block;
        margin-right: 10px;
        width: 15%;
    }

    .s-nav-item-content .s-submenu-image{
        display: contents !important;
    }

    .s-nav-item-content .rs-icon{
        font-size:25px !important;
        top: -5px !important;
        left: 26px !important;
    }

    .s-nav-item-content .s-submenu-icon{
        display: inline-block !important;
        left:67px !important;
    }

    .login-form{
        top:27%;
    }
}

.iconHome {
    font-size: x-large;
      margin-right: 10px;
      font-weight: bold;
      cursor: pointer;
  }
  
  .iconHome:hover {
    color: rgb(var(--p))
  }

.iconCard {
   font-size: 90px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center !important;
    margin-left: 2%;
}

.cards_item {
    display: flex;
    margin: 20px;
    height: 285px;
}

@media (min-width: 40rem) {
    .cards_item {
      width: 50%;
    }
  }
  
  @media (min-width: 56rem) {
    .cards_item {
      width: 21%;
    }
  }
  
  .card {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .rs-panel-body {
    width: 100% !important;
    padding: 10px;
  }

  .panel-body {
    padding: 0px !important;
  }

.iconCheck {
    font-size: x-large;
    font-weight: bold !important;
}
.labelLocation {
    cursor: pointer
}
.labelLocation:hover {
    color: rgb(var(--p));
    cursor:'pointer'
}

.textLocationButton {
    font-size: 16px;
    color: #000;
    font-family: 'Roboto Condensed Regular';
}

.panelImageDetails {
    text-align: center;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accountName {
    color: rgb(0,72,130);
    font-weight: bold;
}